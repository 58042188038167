import { Pinia } from 'pinia';
import { RouteRecordRaw } from 'vue-router';

export default function (store: Pinia) {
  if (process.env.CLIENT && process.env.DEV) {
    console.log(store)
  }
  const routes: RouteRecordRaw[] = [
    // {
    //   path: '/sitemap-live.xml',
    //   component: () => import('pages/SitemapLive.vue'), meta: { layout: 'empty' },
    //   headers: { 'Content-Type': 'application/xml' },
    // },
    {
      path: '/',
      component: () => import('layouts/MainLayout.vue'),
      children: [
        { name: 'homeMain', path: '', component: () => import('pages/Index.vue') },
        { name: 'userLogin', path: '/user-authentication/', component: () => import('pages/UserAuthentication.vue') },
        { name: 'howToUseTelegramBot', path: '/how-to-use-telegram-bot/', component: () => import('pages/HowToUse.vue') },
        { name: 'botsFeatures', path: '/telegram-bot-features/', component: () => import('pages/BotFeatures.vue') },
        { name: 'ourTeam', path: '/telegram-bot-team/', component: () => import('pages/OurTeam.vue') },
        { name: 'serviceMonitoring', path: '/bots-availability-statistics/', component: () => import('pages/AvailabilityStatistics.vue') },
        { name: 'botForEducation', path: '/free-telegram-bot-for-education/', component: () => import('pages/BotForEducation.vue') },
        { name: 'botsPricing', path: '/premium-telegram-bot-pricing/', component: () => import('pages/BotsPricing.vue') },
        { name: 'supportAndContact', path: '/support-and-contact/', component: () => import('pages/ContactUs.vue') },
        { name: 'testingFeatures', path: '/testing-features/', component: () => import('pages/TestingFeatures.vue') },

        { name: 'knowledgeBase', path: '/knowledge-base/:pathMatch(.*)*', redirect() {
            return '/learning-centre/'
        }, },

        { name: 'learningCentre', path: '/learning-centre/', component: () => import('pages/LearningCentre.vue') },
        { name: 'learningCentreContent', path: '/learning-centre/:contentid/', props: true, component: () => import('pages/LearningCentre.vue') },

        { name: 'userWallet', path: '/wallet-and-subscriptions/', meta: {requiresAuth: true}, component: () => import('pages/Wallet.vue') },
        { name: 'userPrivateProfile', path: '/user-profile/', meta: {requiresAuth: true}, component: () => import('pages/UserPrivateProfile.vue') },
        { name: 'groupConfiguration', path: '/group/:groupLocalId/:groupLocalUUID/', meta: {requiresAuth: true}, component: () => import('pages/GroupConfiguration.vue') },
        { name: 'groupConfigurationDeep', path: '/group/:groupLocalId/:groupLocalUUID/:settingsPath/',  meta: {requiresAuth: true}, component: () => import('pages/GroupConfiguration.vue') },

        { name: 'userPublicProfile', path: '/user-profile/:userUUID/', props: true, component: () => import('pages/UserPublicProfile.vue') },
      ],
    },


    {
      name: '404',
      path: '/404-not-found/',
      component: () => import('pages/Error404.vue'),
    },
    // Always leave this as last one,
    // but you can also remove it
    // {

    // }
    // {
    //   path: '/:matchAll(.*)*',
    //   redirect: '/404-not-found/',
    // },
    //   redirect: '/404',

    //   // name: 'notFound',
    //   // component: () => import('pages/Error404.vue'),
    // },
  ];
  return routes;
}
