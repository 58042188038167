// import moment from 'moment';
import moment from 'moment-timezone';
import { useI18n } from 'vue-i18n';

function getCurrentLocale(): string {
  const i18n = useI18n();
  return i18n.locale.value;
}

export function getDateAgo(date: string) {
  moment.locale(getCurrentLocale());
  return moment(date).add(1, 'hour').fromNow();
}

export function nameSurname(name?: string, surname?: string, shouldTrim?: boolean): string {
  let userFullName = ''
  if (name) {
    userFullName += name
  }
  if (surname) {
    if (userFullName != '') {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      userFullName += ` ${surname}`
    } else {
      userFullName += surname
    }
  }
  if (shouldTrim) {
    // cut the name if it's too long
    if (userFullName.length > 20) {
      userFullName = userFullName.substring(0, 20) + '...'
    }
  }
  return userFullName
}

// * funWithObjects
/* eslint-disable @typescript-eslint/no-explicit-any */
export function getTheObjectPath(obj: any, path: string) {
  /* eslint-disable */
  const a = path.split('.')
  let context = obj
  const myregexp = /([a-zA-Z]+)(\[(\d)\])+/ // matches:  item[0]
  let match = null
  for (let i = 0; i < a.length - 1; i += 1) {
    match = myregexp.exec(a[i])
    if (match !== null) context = context[match[1]][match[3]]
    else context = context[a[i]]
  }
  match = myregexp.exec(a[a.length - 1])
  if (match !== null) return context[match[1]][match[3]]
  else return context[a[a.length - 1]]
}

// * User profile rating calculations

function getRating(first: number, second: number) {
  return first || 1 / second || 1 * 5;
}

function getAverageRating(...ratings: number[]) {
  return ratings.reduce((a, b) => a + b, 0) / ratings.length;
}


export function calculateProfileRating(store: any, storePath: any): number {
  store = getTheObjectPath(store, storePath)
  if (store.group_users_aggregate) {
    // * Checking if user is known spammer and have nsfw profile picture
    let isSpammer = store.is_known_abuser ? 0.0 : 5.0;
    let isNSFWPhoto = store.is_profile_pic_nfsw ? 0.0 : 5.0;

    // * Checking groups / admin relationship
    let isAdmin = store.group_admins_aggregate.aggregate.count;
    let isGroupMember = store.group_users_aggregate.aggregate.count;
    let userRating = getRating(isAdmin || 1, isGroupMember);

    let messagesRating = 5.0;
    if (store.punishments_aggregate.aggregate.count > 0) {
      messagesRating = getRating(store.punishments_aggregate.aggregate.count, store.messages_counter);
    }

    let penaltyRating = 5.0;
    if (store.punishments_aggregate.aggregate.count > 0 && store.punishments_aggregate.aggregate.sum.penalty) {
      const penaltiesMax = store.punishments_aggregate.aggregate.count * 15;
      const penaltiesPresent = store.punishments_aggregate.aggregate.count * store.punishments_aggregate.aggregate.sum.penalty;
      penaltyRating = getRating(penaltiesPresent, penaltiesMax);
    }

    var extraMultiplier = 1.0;
    if (store.is_known_abuser) {
      extraMultiplier = 0.10;
    }
    if (store.is_profile_pic_nfsw) {
      extraMultiplier = 0.50;
    }
    // console.log('getAverageRating', userRating, isSpammer, isNSFWPhoto, messagesRating, penaltyRating, extraMultiplier);
    // console.log('getAverageRatingMod', userRating || 0, isSpammer || 0, isNSFWPhoto || 0, messagesRating || 0, penaltyRating || 0);
    return getAverageRating(userRating || 0, isSpammer || 0, isNSFWPhoto || 0, messagesRating || 0, penaltyRating || 0) * extraMultiplier;
  } else {
    return 0.0;
  }
}


export function groupAlreadyHavePackage(groupPaymentObject: any) {
  if (groupPaymentObject.length > 0) {
    return true;
  }
  return false;
}

export function getGroupPackageColor(textColorString: string) {
  if (textColorString !== undefined) {
    return textColorString.replace('text-', '');
  }
}