import { axios } from '../boot/axios'

let axiosStillLoading = 0;
// let axiosIsLoading = false;

function isLoading(isLoading: boolean): boolean {
  if (isLoading) {
    axiosStillLoading++;
  } else {
    axiosStillLoading--;
  }
  return axiosStillLoading > 0;
}

export default function getAxiosLoadingState(): boolean {
  let axiosIsLoading = false;
  axios.interceptors.request.use((config) => {
    // check the domain of the request)
    if (config.url?.includes('clarity')) {
      return config;
    }
    axiosIsLoading = isLoading(true);
    return config;
  }, function(error) {
    axiosIsLoading = isLoading(false);
    if (process.env.DEV) {
      console.log('axios error: ', error);
    }
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    if (response.config.url?.includes('clarity')) {
      return response;
    }
    axiosIsLoading = isLoading(false);
    return response;
  }, function(error) {
    axiosIsLoading = isLoading(false);
    if (process.env.DEV) {
      console.log('axios error: ', error);
    }
    return Promise.reject(error);
  });
  return axiosIsLoading;
}
