
import { createGtm } from '@gtm-support/vue-gtm';
import { boot } from 'quasar/wrappers';

export default boot(({ app, router }) => {
  app.use(createGtm({
    id: 'GTM-K5KFVL7',
    defer: false,
    compatibility: true,
    enabled: true,
    vueRouter: router,
    vueRouterAdditionalEventData: () => ({
      path: router.currentRoute,
    }),
    trackOnNextTick: false,
  }));
})
