import { useMeta } from 'quasar'
import { useI18n } from 'vue-i18n';

const keywords_raw = `best bot for telegram
best bot telegram
best telegram bot
best telegram bot 2022
best telegram bots
best telegram welcome bot
better anti spam bot
bot for telegram
chat groups on telegram
group bot telegram
group bots telegram
how to create a bot for telegram
how to stop spam bots on telegram
link remover bot telegram
manage telegram group
manager telegram group
telegram bot captcha
managing telegram groups
nsfw bot telegram
nsfw telegram
nsfw telegram bot
search group telegram
spam bot for telegram
spam bot telegram
spam bots telegram
telegram add bot to group
telegram anti spam
telegram anti spam bot
telegram anti-spam bot
telegram bot anti-spam
telegram bot ban words
telegram bot captcha
telegram bot group
telegram bot group management
telegram bot list
telegram bot management
telegram bot manager
telegram bot pornfilter
telegram bots for groups
telegram bots for groups
telegram captcha bot
telegram analytics bot
telegram chat bot
telegram community management
telegram create bot
telegram find groups
telegram group management
telegram group management bot
free telegram group bot
telegram group manager bot
telegram group protect
telegram groups
telegram group protection
telegram groups bot
telegram groups links
telegram invite bot
telegram spam bots
telegram spammer bot
telegram spamming bot
telegram welcome bot
telegram group bots
best free telegram bots for groups
telegram welcome bot
telegram admin bot
telegram admin bot
stop bot telegram
stop spam bot telegram
telegram admin bot
telegram admin bots
telegram spam bot
anti spam bot telegram`

function getCurrentPageCanonicalURL(currentRoute: string) {
  if (currentRoute != '') {
    // get current route path
    // const path = window.location.pathname
    if (currentRoute.endsWith('/')) {
      return currentRoute
    } else {
      return currentRoute + '/'
    }
  } else {
    return '/'
  }
}

function twitterDescriptionTrim(description: string) {
  if (description.length > 125) {
    return description.substring(0, 122) + '...'
  } else {
    return description
  }
}

function ogDescriptionTrim(description: string) {
  if (description.length > 65) {
    return description.substring(0, 62) + '...'
  } else {
    return description
  }
}

function getTitle(titleProvided: string) {
  if (titleProvided.length > 40) {
    return titleProvided
  } else {
    return titleProvided + ' | Telegram Bot App'
  }
}

function getTitleTrim(titleProvided: string) {
  if (titleProvided.length < 55) {
    return titleProvided
  } else {
    return titleProvided.substring(0, 52) + '...'
  }
}

export function provideMetadata(options: { title: string, keywords: string[], description: string, currentRoute: string }) {
  let keywords_compiled = keywords_raw.split('\n')
  keywords_compiled = keywords_compiled.sort(() => Math.random() - 0.5).slice(0, 10).map(kw => kw.trim())
  keywords_compiled = options.keywords.concat(keywords_compiled)
  keywords_compiled = [...new Set(keywords_compiled)]
  // remove special characters from all the keywords_compiled
  keywords_compiled = keywords_compiled.map(kw => kw.replace(/[^ ,a-zA-Z0-9]/g, '')).filter(kw => kw.length > 0)
  // join keywords_compiled and remove more than one space
  const keywords_ready = keywords_compiled.join(', ').replace(/\s+/g, ' ')

  const i18n = useI18n();

  const MetaData = {

    title: getTitle(options.title),

    link: {
      canonical: { rel: 'canonical', href: 'https://telegram-bot.app'+ getCurrentPageCanonicalURL(options.currentRoute) }
    },
    meta: {
      description: { name: 'description', content: options.description },
      // keywords: { name: 'keywords', content: "hello" },
      keywords: { name: 'keywords', content: keywords_ready },
      equiv: { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
      // add canonical

      equiv_lang: { 'http-equiv': 'Content-Language', content: i18n.locale.value },

      ogTitle: {
        property: 'og:title',
        content: getTitle(options.title),
      },

      ogType: {
        property: 'og:type',
        content: 'article',
      },

      ogArticleTags: {
        property: 'og:article:tag',
        content: keywords_ready,
      },

      ogArticleAuthor: {
        property: 'og:article:author',
        content: 'https://www.facebook.com/lukasz.raczylo',
      },

      ogArticlePublisher: {
        property: 'og:article:publisher',
        content: 'https://telegram-bot.app' + getCurrentPageCanonicalURL(options.currentRoute),
      },

      ogSiteName: {
        property: 'og:site_name',
        content: 'Telegram Bot App',
      },

      ogUrl: {
        property: 'og:url',
        content: 'https://telegram-bot.app'+getCurrentPageCanonicalURL(options.currentRoute),
      },

      twitterTitle: {
        name: 'twitter:title',
        content: getTitleTrim(options.title),
      },

      ogDescription: {
        property: 'og:description',
        content: ogDescriptionTrim(options.description)
      },

      twitterDescription: {
        name: 'twitter:description',
        content: twitterDescriptionTrim(options.description)
      },

      twitterCard: {
        name: 'twitter:card',
        content: 'summary_large_image'
      },

      twitterSite: {
        name: 'twitter:site',
        content: '@telegramBotApp'
      },

      ogImage: {
        property: 'og:image',
        content: 'https://telegram-bot.app/social-image.png.webp'
      },

      twitterImage: {
        name: 'twitter:image',
        content: 'https://telegram-bot.app/social-image.png.webp'
      }
    },

    noscript: {
      default: options.title + ': ' + options.description,
    }
  }
  useMeta(MetaData)
}
