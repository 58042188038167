import { Cookies } from 'quasar'
import { boot } from 'quasar/wrappers';

let cookie = Cookies
export default boot(({ ssrContext }) => {
  cookie = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies
});

export { cookie };