import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

import { axios } from '../boot/axios'
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

process.env.NODE_TLS_REJECT_UNAUTHORIZED='0';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.GRAPHQL_URL,
  fetch: buildAxiosFetch(axios),
})

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
const errorHandler = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    if (process.env.DEV) {
      console.error('GQL error:', graphQLErrors);
    }
  }
  if (networkError) {
    console.error('GQL Network Error', networkError);
    if (!!networkError['error'] && !!networkError['error']['errors'] && networkError['error']['errors'][0]) {
      console.error('unwrapping apollo network errors');
      networkError.message = networkError['error']['errors'][0].message;
      if (process.env.DEV) {
        console.log('Network error occured', networkError);
      }
      // you may also be able to set networkError.message to null based on criteria to remove the error, even if you can't prevent an error from being triggered altogether
    }
  }
}
);


// Cache implementation
const cache = new InMemoryCache({ addTypename: false, resultCaching: true })

// Create the apollo client
const apolloClient = new ApolloClient({
  errorHandler,
  link: httpLink,
  cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            groups_by_pk: {
              /* eslint-disable @typescript-eslint/no-explicit-any */
              merge(existing, incoming) {
                /* eslint-disable @typescript-eslint/no-unsafe-return */
                return { ...existing, ...incoming };
              }
            }
          }
        }
      }
    }),
  notifyOnNetworkStatusChange: true,
  queryDeduplication: true,
})

export default apolloClient