// import { Pinia } from 'pinia';
import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
// import { StateInterface } from '../store';
import routes from './routes';
import { useUserStore } from '../../src/stores/user';
import * as SharedCookies from '../../src/shared/cookies';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store }) {
  const pinia = store
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: routes(pinia),

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  Router.beforeEach((to, from, next) => {
    // if URL does not end with slash - redirect to URL with slash
    if (to.path.slice(-1) !== '/' && to.path.length > 1) {
      Router.push(to.path + '/').catch(() => {
        // ignore
      });
    }


    if (to.matched.some(record => record.meta.requiresAuth)) {
      SharedCookies.getRefreshedAccessToken();
      if (useUserStore().user_logged_in) {
        next();
      } else {
        next('/user-authentication');
      }
    } else {
      next();
    }
  });

  // Router.afterEach((to, from) => {
  // //   // send google analytics config pageview

  // //   // ...
  // });

  return Router;
});
