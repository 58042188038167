import { defineStore } from 'pinia'
import * as SharedInterfaces from '../shared/interfaces'
import { axios } from '../boot/axios'
import { ExecuteQuery } from '../graphql/query';
import * as GQL from '../graphql/queries';
import { nameSurname } from 'src/shared/commonFunctions';
import { Cookies } from 'quasar';

// useStore could be anything like useUser, useCart
export const useUserStore = defineStore({
  // unique id of the store across your application
  id: 'user-module',
  state: () => ({
    /* eslint-disable @typescript-eslint/no-explicit-any */
    //telegram_details being type of TelegramDetails
    telegram_details: <SharedInterfaces.TelegramDetails>{},
    local_details: <SharedInterfaces.TelegramAuthResponse>{},
    local_profile: <any>{},
    public_profile: {
      is_profile_pic_nfsw: <boolean>false,
      is_known_abuser: <boolean>false,
      first_name: <string>'',
      last_name: <string>'',
      username: <string>'',
      groups_aggregate: <any>{},
      group_users_aggregate: {
        aggregate: {
          count: <number>0,
        },
      },
      group_admins_aggregate: {
        aggregate: {
          count: <number>0,
        },
      },
      punishments_aggregate: {
        aggregate: {
          count: <number>0,
          sum: {
            penalty: <number>0,
          }
        },
      },
      messages_counter: <number>0
    },
  }),

  getters: {
    user_logged_in: (state): boolean => {
      const loggedIn = (state.telegram_details?.id && state.local_details?.access_token) ? true : false
      return loggedIn
    },

    user_full_name: (state) => {
      return nameSurname(state.telegram_details?.first_name, state.telegram_details?.last_name)
    },
  },

  actions: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    setValues(payload: any) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      this.local_details = payload.local_details
      this.telegram_details = payload.telegram_details
    },

    setTelegramUser(telegram_details: SharedInterfaces.TelegramDetails) {
      this.telegram_details = telegram_details;
    },

    async authenticateWithTelegram(telegram_details: SharedInterfaces.TelegramAuthDetails) {
      let response: SharedInterfaces.TelegramAuthResponse;
      try {
        /* eslint-disable @typescript-eslint/restrict-plus-operands */
        response = (await axios.get(process.env.TELEGRAM_AUTH_URL + '/login', {
          params: telegram_details
        })).data;
      } catch (error) {
        console.log('Authentication with telegram failed: ', error, ' for details: ', telegram_details);
        return false;
      }
      if ( process.env.DEV ) {
        console.log('Authentication with telegram response: ', response)
      }
      this.local_details = response;
      return true;
    },

    async getUserPrivateProfile() {
      const response = await ExecuteQuery( GQL.FETCH_USER_PRIVATE_PROFILE, null, true);
      if (response.data && response.data.users.length > 0) {
        this.local_profile = response.data.users[0];
      }
    },

    async getUserPublicProfile(userUUID: string) {
      const response = await ExecuteQuery( GQL.FETCH_USER_PUBLIC_PROFILE, { 'userUUID': userUUID }, false);
      if (response.data ) {
        this.public_profile = response.data.users[0];
      }
    },

    logMeOut() {
      this.local_profile = {};
      this.telegram_details = {};
      Cookies.remove('store-user');
      Cookies.remove('store-app');
    }
  },
})
