import { defineStore } from 'pinia';
import axios from 'axios';
import moment from 'moment';

import * as SharedInterfaces from '../shared/interfaces';
import { ExecuteQuery } from '../graphql/query';
import * as GQL from '../graphql/queries';

// useStore could be anything like useUser, useCart
export const useAppStore = defineStore({
  // unique id of the store across your application
  /* eslint-disable @typescript-eslint/no-explicit-any */
  id: 'app-module',
  state: () => ({
    settings: {
      language: 'en',
      dark_mode: false,
      language_choser: false,
      language_temporary_set: <any>{},
      drawer_toggle: false,
      group_settings_tab: 'settings-home',
    },
    monitoring: {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      healthcheck: {
        AverageRequestTimingNanoseconds: <number>0.0,
        AverageSuccessRatio: <number>0.0,
        IncomingMessages: <number>0.0,
        MicroservicesHealth: <Array<any>>{},
      },
      statistics: {
        data: {
          messages: <Array<any>>{},
          penalty: <Array<any>>{},
          joiners: <Array<any>>{},
          leavers: <Array<any>>{},
          spammer: <Array<any>>{},
        }
      },
      group: {
        joiners: [],
        leavers: [],
        messages: [],
        penalty: [],
        spammer: [],
        users: [
          {
            UserID: <number>0,
            Value: <number>0,
            Index: <number>0,
          }
        ],
      }
    },
    pricing: {
      packages: {}
    },
    general_stats: {},
    groupadmin: {
      available_groups: [],
      groupSettings: {
        groups_by_pk: <SharedInterfaces.GroupSettings>{},
      },
      group_users_stats: [
        {
          user: {
            ambassador: <boolean>false,
            first_name: <string>'loading',
            has_profile_picture: <boolean>false,
            id: <number>0,
            is_profile_pic_nsfw: <boolean>false,
            last_name: <string>'...',
            support: <boolean>false,
            user_handle: <string>'',
            user_uuid: <string>'',
          }
        }
      ]
    },
    payadmin: {
      subscriptions: {},
      old_subscriptions: {}
    }
  }),

  getters: {
  },

  actions: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    setValues(payload: any) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      this.settings = payload.settings
    },

    toggleDrawerMenu() {
      this.settings.drawer_toggle = !this.settings.drawer_toggle
    },

    async getGroupChartStats(groupID: string, timeframe: string) {
      try {
        const response = await axios.get(`/v1/stats/group-stats/${groupID}/${timeframe}`)
          .catch(err => console.log('Unable to check group stats', err));
        if (response) {
          /* eslint-disable @typescript-eslint/no-unsafe-return */
          this.monitoring.group = response.data.data
        }
      } catch (error) {
        if (process.env.DEV)  {
          console.error(error);
        }
        return '{}';
      }
    },

    async getHealtcheck() {
      try {
        const response = await axios.get('/v1/monitoring/microservices-health')
          .catch(err => console.log('Unable to check microservices health', err));
        if (response) {
          this.monitoring.healthcheck = response.data;
        }
      } catch (error) {
        if (process.env.DEV)  {
          console.error(error);
        }
        return '{}';
      }
    },

    async getAvailabilityStatistics() {
      try {
        const response = (await axios.get('/v1/stats/general-statistics-flux/hourly')).data;
        this.monitoring.statistics = response;
      } catch (error) {
        if (process.env.DEV)  {
          console.error(error);
        }
        return '{}';
      }
    },

    async getGeneralStats() {
      try {
        const response = await ExecuteQuery( GQL.FETCH_MAIN_COUNTER, );
        this.general_stats = response.data;
      } catch (error) {
        if (process.env.DEV)  {
          console.error(error);
        }
        return '{}';
      }
    },

    async getAvailablePackages() {
      const response = await ExecuteQuery( GQL.FETCH_PACKAGES_PRICING, );
      this.pricing.packages = response.data
    },

    async getGroupadminGroups() {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUPS, null, true, 'groupadmin');
      this.groupadmin.available_groups = response.data
    },

    CheckIfSettingsForThisGroupExistsAndMerge(response:any) {
      if(response.groups_by_pk?.group_uuid === this.groupadmin.groupSettings.groups_by_pk?.group_uuid) {
        return {
          groups_by_pk: {
            ...this.groupadmin.groupSettings.groups_by_pk,
            ...response.groups_by_pk,
          }
        }
      } else {
        /* eslint-disable @typescript-eslint/no-unsafe-return */
        return {
          groups_by_pk: response.groups_by_pk,
        }
      }
    },

    async getGroupadminSettingsHome(groupID: string, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_HOME, { 'groupID': groupID, 'expiryDate': moment() }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsStats(groupID: string, invalidateCache?: boolean) {
      let usersStats = this.monitoring.group.users;
      if (usersStats.length === 0) {
        usersStats = [];
      }
      usersStats.sort((a, b) => {
        return b.Value - a.Value;
      });
      usersStats = usersStats.slice(0, 15);
      const userStatsIdArray = usersStats.map(user => user.UserID);
      if (userStatsIdArray.length > 0 && this.groupadmin) {
        try {
          const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_STATS, { 'groupID': groupID, 'userIDArray': userStatsIdArray, 'expiryDate': moment() }, true, 'groupadmin', false, invalidateCache);
          /* eslint-disable @typescript-eslint/no-unsafe-call */
          const group_user = response.data.groups_by_pk.group_users;
          const sorted_group_user = <any>[];
          for (const id of userStatsIdArray) {
            sorted_group_user.push(group_user?.find((user: any) => user.user.id === id));
          }
          this.groupadmin.groupSettings = response.data;
          this.groupadmin.group_users_stats = sorted_group_user;
        } catch (error) {
          if (process.env.DEV)  {
            console.error(error);
          }
          return;
        }
      }
    },

    async getGroupadminSettingsMedia(groupID: string, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_MEDIA, { 'groupID': groupID, 'expiryDate': moment() }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsSpam(groupID: string, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_SPAM, { 'groupID': groupID }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsBot(groupID: string, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_BOT, { 'groupID': groupID }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsPunishments(groupID: string, offset: number, invalidateCache?: boolean, filter?: string) {
      const reason = filter == 'all' ? '%' : `%${filter || ''}%`;
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_PUNISHMENTS, { 'groupID': groupID, 'offset': offset, 'penaltyReason': reason }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
      // this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsAudit(groupID: string, offset: number, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_AUDIT, { 'groupID': groupID, 'offset': offset }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getGroupadminSettingsBadwords(groupID: string, invalidateCache?: boolean) {
      const response = await ExecuteQuery( GQL.FETCH_GROUPADMIN_GROUP_SETTINGS_BADWORDS, { 'groupID': groupID,'expiryDate': moment() }, true, 'groupadmin', false, invalidateCache);
      this.groupadmin.groupSettings = this.CheckIfSettingsForThisGroupExistsAndMerge(response.data);
    },

    async getPayAdminSubscriptions(invalidateCache?: boolean) {
      // get date month ago
      const response = await ExecuteQuery( GQL.FETCH_PAYADMIN_SUBSCRIPTIONS_ACTIVE, null, true, 'payadmin', false, invalidateCache);
      this.payadmin.subscriptions = response.data;
    },
    async getPayAdminSubscriptionsInactive(invalidateCache?: boolean) {
      // get date month ago
      const response = await ExecuteQuery( GQL.FETCH_PAYADMIN_SUBSCRIPTIONS_INACTIVE, null, true, 'payadmin', false, invalidateCache);
      this.payadmin.old_subscriptions = response.data;
    },

    async setNewBadword(badword: string, groupid: string) {
      try {
      const response = await ExecuteQuery( GQL.SET_GROUPADMIN_NEW_BADWORD, { 'badword': badword, 'groupID': groupid }, true, 'groupadmin', true);
      if (response.data.errors) {
        return false
      }
      return true
      } catch (error) {
        return false
      }
    },

    async setGroupadminSettingsLanguage(groupID: string, language: string) {
      try {
        const result = await ExecuteQuery( GQL.SET_GROUPADMIN_GROUP_SETTINGS_LANGUAGE, { 'groupID': groupID, 'language': language }, true, 'groupadmin', true);
        if (result.data?.update_groups_by_pk?.id) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
      return true
    },

    async deleteGroupadminPunishment(punishmentID: string) {
      try {
      const result = await ExecuteQuery( GQL.DELETE_GROUPADMIN_GROUP_SETTINGS_PUNISHMENT, { 'punishmentID': punishmentID }, true, 'groupadmin', true);
        if (result.data?.delete_punishments_by_pk?.id) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
      return true
    },

    async deleteGroupadminBadword(badwordID: number) {
      try {
      const result = await ExecuteQuery( GQL.DELETE_GROUPADMIN_GROUP_SETTINGS_BADWORD, { 'badwordID': badwordID}, true, 'groupadmin', true);
        if (result.data?.delete_badwords_by_pk?.id) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
      return true
    },

    async setGroupadminSettings(groupID: string, settingName: string, value: any) {
      try {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      /* eslint-disable @typescript-eslint/no-var-requires */
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      // value = value.replace(/%/g, '\\%');

      let valType = 'String!';
      if ((value === true) || (value == false)) {
        valType = 'Boolean!'
      } else if (Number.isInteger(value)) {
        valType = 'Int!'
      }
      const groupSettingsChangeQuery = `mutation setGroupSettings($groupID: bigint!, $value: ${valType}) {
        update_groups_by_pk(pk_columns: {id: $groupID}, _set: {${settingName}: $value}) {
          ${settingName}
        }
      }`
      const response = await ExecuteQuery( groupSettingsChangeQuery, { 'groupID': groupID, 'value': value }, true, 'groupadmin', true);
      const val = response.data?.update_groups_by_pk[settingName];

      this.groupadmin.groupSettings = {
        ...this.groupadmin.groupSettings,
        groups_by_pk: {
          ...this.groupadmin.groupSettings.groups_by_pk,
          [settingName]: val
        }
      }
      return true
    } catch (error) {
      if (process.env.DEV)  {
        console.error('Query error', error);
      }
      return false;
    }
    },

  async sendStripeSessionRequest(orderDetails: any) {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${this.user.token}`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    try {
      const response = await axios.post('/v1/payments/create-checkout-session', orderDetails).catch(error => {
        if (process.env.DEV)  {
          console.error('Query error', error);
        }
        return false;
      });
      // if response type is boolean, it means there was an error
      if (typeof response === 'boolean') {
        console.log('Error in sending stripe session request');
        return false;
      }
      console.log('response', response);
      return response.data;
    } catch (error) {
      if (process.env.DEV)  {
        console.error('Query error tried', error);
      }
      return false;
    }
  },

  async getPubliclyAvailablePackages () {
    const response = await ExecuteQuery( GQL.PUBLIC_GET_AVAILABLE_PACKAGES, {}, false, 'guest', false);
    this.pricing.packages = response.data;
  }
  }
})
