import enUS from './lang/en.json';
import plPL from './lang/pl.json';
import ruRU from './lang/ru.json';
import frFR from './lang/fr.json';
import faIR from './lang/fa.json';
import idID from './lang/id.json';
import esES from './lang/es.json';
import hiIN from './lang/hi.json';
import arSA from './lang/ar.json';
import zhCN from './lang/zh-Hant.json';
import trTR from './lang/tr.json';
import itIT from './lang/it.json';
import nlNL from './lang/nl.json';

import am from './lang/am.json';
import bg from './lang/bg.json';
import bn from './lang/bn.json';
import ro from './lang/ro.json';
import cs from './lang/cs.json';
import te from './lang/te.json';
import uk from './lang/uk.json';
import ja from './lang/ja.json';
import ur from './lang/ur.json';
import he from './lang/he.json';




export default {
  'en-US': enUS,
  en: enUS,
  pl: plPL,
  ru: ruRU,
  fr: frFR,
  fa: faIR,
  id: idID,
  es: esES,
  hi: hiIN,
  ar: arSA,
  zh: zhCN,
  tr: trTR,
  it: itIT,
  nl: nlNL,
  am,
  bg,
  ro,
  cs,
  te,
  uk,
  ja,
  ur,
  bn,
  he
}
