export const FETCH_MAIN_COUNTER = `
  query generalStatistics {
    messages: messages_aggregate {
      aggregate {
        max {
          id
        }
      }
    }
    users: users_aggregate {
      aggregate {
        count
      }
    }
    groups: groups_aggregate {
      aggregate {
        count
      }
    }
  }`

export const FETCH_PACKAGES_PRICING = `
  query packagePricing {
    group_packages(where: {is_active: {_eq: true}}) {
      package_discount
      package_name
      package_price
      package_size
      packageTypeByPackageType {
        name
      }
    }
  }`

export const FETCH_USER_PUBLIC_PROFILE = `
  query userPublicProfile($userUUID: uuid!) {
    users(where: {user_uuid: {_eq: $userUUID}}) {
      last_name
      last_seen
      first_name
      ambassador
      support
      messages_counter
      profile_description
      punishments_aggregate {
        aggregate {
          sum {
            penalty
          }
          count
        }
      }
      user_uuid
      user_handle
      is_profile_pic_nfsw
      is_known_abuser
      has_web_access
      has_profile_picture
      group_admins_aggregate {
        aggregate {
          count
        }
      }
      group_users_aggregate {
        aggregate {
          count
        }
      }
    }
  }`

export const FETCH_USER_PRIVATE_PROFILE = `
  query userPrivateProfile {
    users {
      last_name
      last_seen
      first_name
      messages_counter
      ambassador
      support
      group_users {
        messages_counter
        group {
          name
          group_uuid
          group_admins {
            id
          }
          messages(limit: 3, order_by: {created_at: desc}) {
            content(path: ".text")
            created_at
            id
          }
        }
      }
      group_admins_aggregate {
        aggregate {
          count
        }
      }
      group_users_aggregate {
        aggregate {
          count
        }
      }
      bots {
        bot_name
        bot_uuid
      }
      punishments_aggregate {
        aggregate {
          sum {
            penalty
          }
          count
        }
      }
      user_uuid
      user_handle
      is_profile_pic_nfsw
      is_known_abuser
      has_web_access
      has_profile_picture
    }
  }`

export const FETCH_GROUPADMIN_GROUPS = `
  query userAvailableGroups {
    group_admins(distinct_on: group_id) {
      group {
        name
        group_uuid
        id
        bot {
          bot_name
          bot_handle
        }
        invite_link_rights
        group_picture_last_checked
        has_group_picture
        group_picture_nsfw
        updated_at
        sub_payments_to_groups(where: {sub_user_payment: {active: {_eq: true}}}, order_by: {sub_user_payment: {sub_group_package: {position: desc}}}, limit: 1) {
          sub_user_payment {
            sub_group_package {
              package_name
              color: meta(path: ".color")
            }
            expires_at
          }
        }
      }
    }
  }`

// ! Following query requires groupID parameter!
export const FETCH_GROUPADMIN_GROUP_SETTINGS_HOME = `
query groupConfigurationHome($groupID: bigint!, $expiryDate: timestamp!) {
  groups_by_pk(id: $groupID) {
    bot {
      bot_handle
      bot_name
    }
    group_picture_nsfw
    has_group_picture
    invite_link_rights
    description
    messages_count
    name
    group_admins {
      id
      user {
        first_name
        last_name
        last_seen
        has_profile_picture
        is_profile_pic_nfsw
        user_handle
        user_uuid
        ambassador
        support
      }
    }
    sub_payments_to_groups(where: {sub_user_payment: {active: {_eq: true}}}, order_by: {sub_user_payment: {sub_group_package: {position: desc}}}, limit: 1) {
      sub_user_payment {
        sub_group_package {
          package_name
          color: meta(path: ".color")
          feat_media
          feat_voice
        }
        expires_at
        user {
          first_name
          last_name
        }
      }
      used_media
      used_transcribe
    }
    sub_payments_to_groups_aggregate(where: {sub_user_payment: {expires_at: {_gt: $expiryDate}}}) {
      aggregate {
        count
      }
    }
    set_lang
    set_lang_guard
    set_lang_delete_only
    set_enable_greeting
    set_greeting_text
    set_group_rules
    set_enable_group_rules
    set_group_rules_auto
    group_uuid
    updated_at
    group_users_aggregate {
      aggregate {
        count
      }
    }
  }
}`

export const FETCH_GROUPADMIN_GROUP_SETTINGS_STATS = `
query TopUsersInGroupSelect($groupID: bigint!, $userIDArray: [bigint!], $expiryDate: timestamp!) {
  groups_by_pk(id: $groupID) {
    id
    name
    group_uuid
    set_hide_from_stats
    group_users(where: {user_id: {_in: $userIDArray}, _and: {_not: {user: {is_known_abuser: {_eq: true}}, _or: {user: {user_handle: {_ilike: "%bot"}}}}}}) {
      user {
        id
        last_name
        is_profile_pic_nfsw
        first_name
        user_uuid
        has_profile_picture
        support
        ambassador
        user_handle
      }
    }
    sub_payments_to_groups_aggregate(where: {sub_user_payment: {expires_at: {_gt: $expiryDate}}}) {
      aggregate {
        count
      }
    }
  }
}`

export const FETCH_GROUPADMIN_GROUP_SETTINGS_MEDIA = `
  query groupConfigurationMedia($groupID: bigint!, $expiryDate: timestamp!) {
    groups_by_pk(id: $groupID) {
      name
      group_uuid
      updated_at
      set_no_video
      set_no_files
      set_no_gif
      set_ocr
      set_no_audio
      set_scan_content_porn
      set_scan_content_racy
      set_scan_content_spoof
      set_scan_content_strict
      set_scan_gifs
      set_scan_pictures
      set_scan_stickers
      set_scan_user_profile_img
      set_scan_videos
      sub_payments_to_groups_aggregate(where: {sub_user_payment: {expires_at: {_gt: $expiryDate}}}) {
        aggregate {
          count
        }
      }
    }
  }`

  export const FETCH_GROUPADMIN_GROUP_SETTINGS_SPAM = `
  query groupConfigurationSpam($groupID: bigint!) {
    groups_by_pk(id: $groupID) {
      name
      group_uuid
      updated_at
      set_no_video
      set_no_files
      set_no_gif
      set_no_audio
      set_no_text
      set_no_invites
      set_no_profanities
      set_no_forwards
      invite_link
      invite_link_rights
    }
  }`

  export const FETCH_GROUPADMIN_GROUP_SETTINGS_BADWORDS = `
  query groupConfigurationBadwords($groupID: bigint!, $expiryDate: timestamp!) {
    groups_by_pk(id: $groupID) {
      name
      group_uuid
      updated_at
      set_badwords
      set_badwords_penalty
      set_no_profanities
      badwords {
        id
        word
        created_at
        user {
          first_name
          last_name
          is_profile_pic_nfsw
          user_handle
          user_uuid
          has_profile_picture
        }
      }
      set_no_insults
      set_no_threats
      sub_payments_to_groups(order_by: {sub_user_payment: {expires_at: desc}}, where: {sub_user_payment: {active: {_eq: true}}}) {
        sub_user_payment {
          expires_at
          sub_group_package {
            feat_badwords
          }
        }
      }
      sub_payments_to_groups_aggregate(where: {sub_user_payment: {expires_at: {_gt: $expiryDate}}}) {
        aggregate {
          count
        }
      }
    }
  }`

  export const FETCH_GROUPADMIN_GROUP_SETTINGS_BOT = `
  query groupConfigurationBot($groupID: bigint!) {
    groups_by_pk(id: $groupID) {
      name
      group_uuid
      updated_at
      set_spam_ai
      set_mute_bot
      set_delete_system_msgs
      set_limit_anonymous
      set_captcha
      set_captcha_time
      set_captcha_kick_on_timeout
    }
  }`

export const FETCH_GROUPADMIN_GROUP_SETTINGS_PUNISHMENTS = `
  query groupConfigurationPenalties($groupID: bigint!, $offset: Int!, $penaltyReason: String!) {
    groups_by_pk(id: $groupID) {
      name
      group_uuid
      bot {
        bot_handle
        bot_name
      }
      unique_punishments: punishments(distinct_on: reason){
        reason
      }
      punishments(limit: 10, offset: $offset, order_by: {created_at: desc}, where: {reason: {_cast: {String: {_like: $penaltyReason}}}}) {
        created_at
        penalty
        punishment_uuid
        message {
          content
        }
        user {
          first_name
          has_profile_picture
          is_profile_pic_nfsw
          last_name
          user_handle
          user_uuid
        }
        reason
        id
      }
      punishments_aggregate(where: {reason: {_cast: {String: {_like: $penaltyReason}}}}) {
        aggregate {
          count
        }
      }
    }
  }`

  export const FETCH_GROUPADMIN_GROUP_SETTINGS_AUDIT = `
  query getGroupAdminLog($groupID: bigint!, $offset: Int!) {
    groups_by_pk(id: $groupID) {
      id
      group_uuid
      groups_settings_logs(offset: $offset, limit: 10, order_by: {created_at: desc}) {
        id
        data
        created_at
        settings_log_uuid
        user {
          first_name
          user_uuid
          user_handle
          last_name
          is_profile_pic_nfsw
          has_profile_picture
        }
      }
      name
      groups_settings_logs_aggregate {
        aggregate {
          count
        }
      }
    }
  }`

export const DELETE_GROUPADMIN_GROUP_SETTINGS_PUNISHMENT = `
  mutation deleteUserPunishment($punishmentID: bigint!) {
    delete_punishments_by_pk(id: $punishmentID) {
      id
    }
  }`

export const DELETE_GROUPADMIN_GROUP_SETTINGS_BADWORD = `
mutation deleteGroupBadword($badwordID: bigint!) {
  delete_badwords_by_pk(id: $badwordID) {
    id
  }
}`

export const SET_GROUPADMIN_NEW_BADWORD = `
mutation addNewBadword($groupID: bigint!, $badword: String!) {
  insert_badwords_one(object: {group_id: $groupID, word: $badword}) {
    id
  }
}`

export const SET_GROUPADMIN_GROUP_SETTINGS_LANGUAGE = `
  mutation setGroupLanguage($groupID: bigint!, $language: String!) {
    update_groups_by_pk(pk_columns: {id: $groupID}, _set: {set_lang: $language}) {
      id
    }
  }`


export const PUBLIC_GET_AVAILABLE_PACKAGES = `
  query getAvailablePackages {
    sub_group_packages(order_by: {position: asc}, distinct_on: position, where: {enabled: {_eq: true}}) {
      basic_features
      description
      enabled
      id
      meta
      package_name
      premium_features
      price_monthly
      price_single
      price_yearly
      position
    }
  }`

// ! PAYADMIN SECTION

export const FETCH_PAYADMIN_SUBSCRIPTIONS_ACTIVE = `
query findPayAdminSubscriptions {
  sub_user_payments(order_by: {created_at: desc}, where: {active: {_eq: true}}) {
    metadata
    interval
    active
    created_at
    expires_at
    stripe_cus
    is_test_env
    sub_uuid
    sub_group_package {
      package_name
      color: meta(path: ".color")
    }
    sub_payments_to_groups {
      group {
        group_uuid
        name
        group_picture_nsfw
        has_group_picture
      }
      used_transcribe
      used_media
    }
    invoice_link
    id
  }
}`

export const FETCH_PAYADMIN_SUBSCRIPTIONS_INACTIVE = `
query findPayAdminSubscriptions {
  sub_user_payments(order_by: {created_at: desc}, where: {active: {_eq: false}}) {
    metadata
    interval
    active
    created_at
    expires_at
    stripe_cus
    is_test_env
    sub_uuid
    sub_group_package {
      package_name
      color: meta(path: ".color")
      price_monthly(path: ".price")
      price_single(path: ".price")
      price_yearly(path: ".price")
    }
    sub_payments_to_groups {
      group {
        group_uuid
        name
        group_picture_nsfw
        has_group_picture
      }
      used_transcribe
      used_media
    }
    id
  }
}`
