import { boot } from 'quasar/wrappers';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default boot(({ app }) => {
  if(process.env.DEV) {
    return
  }

  Sentry.init({
    app,
    dsn: process.env.SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://dev.telegram-bot.app', 'https://telegram-bot.app'],
      })
    ],
    environment: process.env.DEV ? 'development' : 'production',
    release: process.env.meta.METADATA_VERSION || 'local',
    autoSessionTracking: true,
    trackComponents: false,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
});