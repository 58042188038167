import apolloClient from './graphql';
import gql from 'graphql-tag';
// import { axios } from '../boot/axios'
import { useUserStore } from '../stores/user';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const ExecuteQuery = async (queryContent: string, queryVariables?: any, isAuthenticated?: boolean, authRole?: string, mutation?: boolean, refetch?: boolean) => {
  try {
    let queryContext = {};
    if ( isAuthenticated ){
      const userStore = useUserStore();
      if (userStore.local_details.hasOwnProperty('access_token')) {
        const accessToken = userStore.local_details.access_token;
        // * User logged in, adding authorization header
        queryContext = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-hasura-role': authRole? authRole : 'user',
          }
        }
        if (process.env.DEV) {
          console.log('Query executed within context', queryContext);
        }
      } else {
        // * User not logged in / no token property - aborting
        if (process.env.DEV)  {
          console.log('Auth query requested, no auth data available');
        }
        return {'data': {}};
      }
    }
    if (mutation) {
      const mutationResponse = await apolloClient.mutate({
        mutation: gql(queryContent),
        variables: queryVariables,
        context: queryContext? queryContext : {},
      });
      return mutationResponse;
    } else {
      const returnValue = await apolloClient.query({
          query: gql(queryContent),
          variables: queryVariables,
          context: queryContext? queryContext : {},
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        });
      return returnValue;
    }

  } catch (error) {
    if (process.env.DEV) {
      console.error(error, 'Query content:', queryContent, '\n', 'Query variables:', queryVariables, '\n', 'Query host:', process.env.GRAPHQL_URL);
    }
    return {'data': {}};
  }
}
