import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

export default boot(({ app }) => {
  const axiosRetryConn = axiosRetry;
  const api = axios.create({ baseURL: process.env.APP_URL || 'https://telegram-bot.app' });
  axios.create({ baseURL: process.env.APP_URL || 'https://telegram-bot.app'});
  axiosRetryConn(api, { retries: 3 });
  // for use inside Vue files (Options API) through this.$axios and this.$api

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-empty-function */
  axios.interceptors.response.use(function (response) {
    return response;
  }, function(error) {
    // if (error.status === 401) {
      if (process.env.DEV) {
        console.log('Axios interceptor error:', error);
      }
      // cancel promise
      // reject promise without error
      return Promise.reject(error);
      // return Promise.reject(error).catch(() => console.clear());
      // return new Promise(() => {});
    // } else {
    //   return Promise.reject(error);
    // }
  });

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { axios };
