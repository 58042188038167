import { Notify } from 'quasar';
import { boot } from 'quasar/wrappers';

export default boot(({ /*app*/ }) => {
  Notify.setDefaults({
    position: 'top',
    timeout: 2500,
    color: 'negative',
    textColor: 'white',
    actions: [{ icon: 'close', color: 'white' }]
  });
});
