import { cookie } from 'boot/cookies'
import { useUserStore } from '../stores/user';
import { useAppStore } from '../stores/app';
// import { storeToRefs } from 'pinia';
import jwt_decode from 'jwt-decode';
import { axios } from '../boot/axios'

function prepareStore() {
  // const quasar = useQuasar();
  const userStoreRef = useUserStore();
  const appStoreRef = useAppStore();
  return { userStoreRef, appStoreRef };
}

export interface cookieLanguage {
  language: string;
  override: boolean;
}

export function setLanguageDetermine(browserLocale: string): string {
  // if (!browserLocale) {
  //   return 'en';
  // }
  if (cookie.has('language')) {
    console.log('Ignored browser locale, ', browserLocale, ' using cookie language');
    return setLanguageFromCookie();
  } else {
    return 'en';
    // return setLanguageIntoCookie(browserLocale);
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
export function setLanguageFromCookie(): string {
  try {
    const languageCookie = cookie.get('language') as cookieLanguage;
    return languageCookie.language || 'en';
  } catch (error) {
    return 'en';
  }
}

export function setLanguageIntoCookie(lng: string) {
  const languageCookie: cookieLanguage = {
    language: lng,
    override: true
  }
  cookie.set('language', JSON.stringify(languageCookie));
  return lng
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function extractJWTExpiration(tokens: any, token_type: string) {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const decodedJWTToken: any = jwt_decode(tokens[token_type]);
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  return new Date(decodedJWTToken.exp * 1000);
}

export function setSessionDataAfterLogin() {
  const { userStoreRef } = prepareStore();
  // * Setting token as user is logged in
  try {
    const cookieExpirationDate = extractJWTExpiration(userStoreRef.local_details, 'refresh_token');
    cookie.set('store-app', JSON.stringify(useAppStore().$state), { expires: cookieExpirationDate, secure: true });
    cookie.set('store-user', JSON.stringify(useUserStore().$state), { expires: cookieExpirationDate, secure: true });
  } catch (error) {
    if(process.env.DEV) {
      console.log('Error setting session data after login:', error);
    }
    return false
  }
  return true
}

/* eslint-disable @typescript-eslint/require-await */
export function getRefreshedAccessToken(){
  const areCookiesPresent = setSessionDataFromCookies();
  if ( areCookiesPresent || process.env.CI ) {
    const { userStoreRef } = prepareStore();
    const refresh_token = userStoreRef.local_details.refresh_token;
    /* eslint-disable @typescript-eslint/restrict-plus-operands */
    axios.get(process.env.TELEGRAM_AUTH_URL + '/refresh_token', {
      headers: {
        'Authorization': `Bearer ${refresh_token}`,
        'BypassAuth': process.env.CI ? 'true' : '',
      }
    }).then(response => {
      userStoreRef.local_details = response.data;
    }).catch(error => {
      if (process.env.DEV) {
        console.log('authURL', error);
      }
      return false
    });
    setSessionDataAfterLogin();
    return true
  }
  return false
}

export function setSessionDataFromCookies() {
  const cookieAppStore = cookie.get('store-app');
  const cookieUserStore = cookie.get('store-user');
  if (cookieAppStore) {
    try {
      useAppStore().setValues(cookieAppStore);
    } catch (e) { console.log('cookieAppStore error', e) }
  }
  if (cookieUserStore) {
    try {
      useUserStore().setValues(cookieUserStore);
    } catch (e) {
      console.log('cookieUserStore error', e);
     }
  }
  if (cookieAppStore && cookieUserStore) {
    return true;
  }
  return false;
}
