
import { useQuasar } from 'quasar';
import { defineComponent, onBeforeMount, watch } from 'vue';
// import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '../src/stores/app';
// import { useUserStore } from '../src/stores/user';
import * as SharedCookies from '../src/shared/cookies';
import getAxiosLoadingState from '../src/shared/loadingBarSupport';
import * as MetaData from './shared/metadata';
import { useRoute } from 'vue-router';

 /* eslint-disable @typescript-eslint/no-var-requires */
var CountryLanguage = require('@ladjs/country-language');

type myCustomIcons = {
  [key: string]: string
}

const myCustomIcons: myCustomIcons = {
  'chevron_left': 'fa-light fa-caret-left',
  'chevron_right': 'fa-light fa-caret-right',
  'chevron_up': 'fa-light fa-caret-up',
  'chevron_down': 'fa-light fa-caret-down',
  'arrow_drop_down': 'fa-light fa-caret-down',
  'arrow_drop_up': 'fa-light fa-caret-up',
  'close': 'fa-light fa-xmark',
  'cancel': 'fa-solid fa-circle-xmark',
}

export default defineComponent({
  name: 'App',

  setup () {

    const route = useRoute();

    MetaData.provideMetadata({
      title: 'Best telegram group management bot',
      description: 'Best telegram group management bot to keep your group secure. Start now with filtering spam, profanities, scan for viruses and more.',
      keywords: [
        'telegram bot', 'telegram group management bot', 'free telegram group management bot', 'telegram group', 'telegram bot creator',
        'telegram porn bot', 'telegram bot for group management', 'telegram bot manager'
      ],
      currentRoute: route.path,
    });

    const $q = useQuasar();
    const appStore = useAppStore();
    const i18n = useI18n({ useScope: 'global' });

    $q.loadingBar.setDefaults({
      // intercept: false,
      color: 'secondary',
      size: '5px',
      position: 'top'
    })

    if ( getAxiosLoadingState() ) {
      $q.loadingBar.start();
    } else {
      $q.loadingBar.stop();
    }

    // $q.iconMapFn = (iconName) => {
    //   const icon = myCustomIcons[iconName]
    //   if (icon !== void 0) {
    //     return { icon: icon }
    //   }
    // }

    /* eslint-disable @typescript-eslint/no-unsafe-call */
    // get browser language
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    i18n.locale.value = SharedCookies.setLanguageDetermine(CountryLanguage.getLanguage($q.lang.getLocale()?.split('-')[0]).iso639_1);
    onBeforeMount(() => {
      i18n.locale.value = SharedCookies.setLanguageDetermine(CountryLanguage.getLanguage($q.lang.getLocale()?.split('-')[0]).iso639_1);
    });

    $q.lang.rtl = i18n.locale.value === 'ar' || i18n.locale.value === 'he' || i18n.locale.value === 'fa';
    // $q.lang.set(lang: {
    //   isoName: i18n.locale.value
    // });

    // SharedCookies.getRefreshedAccessToken();
    $q.dark.set(appStore.settings.dark_mode);
    // i18n.locale.value = appStore.settings.language;

    // watch(() => route.params, () => {
    //   // appStore.getHealtcheck().catch(
    //   //   () => {
    //   //     $q.notify({
    //   //       message: 'Could not obtain healtcheck data',
    //   //       color: 'negative',
    //   //       icon: 'warning'
    //   //     })
    //   //   }
    //   // );
    //   if(userStore.user_logged_in) {
    //     SharedCookies.getRefreshedAccessToken();
    //   }
    // })

    watch(() => appStore.settings.dark_mode, (val) => {
      $q.dark.set(val);
    })

    // watch(() => appStore.settings.language, (val) => {
    //   i18n.locale.value = val;
    // })

    return {
      appStore
    }
  }
})
